import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Container } from 'components/container/Container';


import { LandingLayout } from 'layouts'
/*
import { TweenLite } from 'gsap';
*/
import s from 'styles/pages/privacy-policy.scss';

const menuItems = [
	{
		button: false,
		name: 'Go back',
		to: '/register-now',
		anchor: false,
	},
]

export default () => {
	return(
	  <LandingLayout menuItems={menuItems}>
	    <Helmet>
            <title>Privacy Policy - Hair, Beauty, Fittnes and Aesthetics</title>
            <meta name="description" content="Take control of your hair and beauty business. Growing your beauty business can be made easier with JustMe." />
		    <script>
		        {
				  `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				  n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
				  n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
				  t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
				  document,'script','https://connect.facebook.net/en_US/fbevents.js');
				  fbq('init', ${process.env.FACEBOOK_PIXEL_ID}); 
				  fbq('track', 'PageView');`
		        }
		    </script>
		    <noscript>
		    {
		    	`<img height="1" width="1" style="display:none"
				  src="https://www.facebook.com/tr?id=${process.env.FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1"
				/>`
			}
		    </noscript>

	    </Helmet>



	    <div className={`${s.sectionlanding}`}>
	    	<Container>
	    		<div className={s.container_doc}>
	    				<h1 className={s.title__subtitle}>Privacy Policy</h1>
			    		<h3 className={s.title__big}>1. General Information</h3>
			    		<p className={s.text}>
			    			The Just Me services, mobile app and website application (<strong>Just Me Services</strong>) are provided by Just Me Associates Ltd, company reference number 11592470 (<strong>Just Me</strong>).
						</p>
						<p className={s.text}>
							This policy (together with the terms and conditions as set out at www.justme.net (<strong>Terms &amp; Conditions</strong>)) applies to your use of the Just Me Services and sets out the basis on which any personal data Just Me collects from you, or that you provide to Just Me, will be processed by Just Me.
						</p>
						<p className={s.text}>
							Start getting new clients in 2020!
			    		</p>
			    		<h3 className={`${s.title__big} ${s.title__spacing}`}>2. What information does Just Me collect?</h3>
						<p className={s.text}>
							Just Me collects the following data from you.
			    		</p>
			    		<ul className={`${s.list_roman}`}>
			    			<li>
			    				Just Me collects and processes the following personal data relating to you which you provide when you register for the Just Me Services:
			    				<ol className={`${s.list_disc}`}>
			    					<li>username (which may or may not contain your name, and may therefore not always be personal data);</li>
			    					<li>email address;</li>
			    					<li>telephone number;</li>
			    					<li>age;</li>
			    					<li>biometric data;</li>
			    					<li>debit/credit card details; and</li>
			    					<li>IP address.</li>
			    				</ol>
			    			</li>
			    			<li>
								Just Me collects and processes the following data called data-log files following access to the Just Me Services:
			    				<ol className={`${s.list_disc}`}>
			    					<li>name of website accessed;</li>
			    					<li>URL;</li>
			    					<li>date and time of retrieval;</li>
			    					<li>amount of data transferred;</li>
			    					<li>message about successful retrieval;</li>
			    					<li>browser type and version;</li>
			    					<li>user's operating system;</li>
			    					<li>referrer URL (previously visited page); and</li>
			    					<li>IP address and the requesting party Provider,</li>
			    				</ol>
			    			</li>

			    		</ul>
			    		<h3 className={`${s.title__big} ${s.title__spacing}`}>3. What is your data used for?</h3>
			    		<p className={s.text}>Just Me processes the following data in the following ways, and on the following legal bases:</p>
			    		<ul className={`${s.list_disc}`}>
			    			<li>Your username and your age for the purposes of identification on the Just Me Services, for age verification and for your login credentials. Just Me does this on the bases that:</li>
			    			<ul className={`${s.list_disc}`}>
			    				<li>it is necessary for the performance of Just Me’s contract with you under which it is obliged to provide you with the Just Me Services; and</li>
			    				<li>it is necessary in Just Me’s legitimate interest in providing the Just Me Services in an effective manner.</li>
			    			</ul>
			    			<li>To send you email marketing about similar products and services which we may provide to you. You will always have the option to opt-out of these marketing emails.</li>
			    			<li>To carry out Just Me’s obligations arising from any contracts entered into between Just Me and you, including passing your details to the Partner that you have placed your booking with. Just Me does this on the basis that it is necessary for the performance of Just Me’s contract with you.</li>
			    			<li>To personalise your experience of the Just Me Services, to help Just Me respond better to your individual needs and to improve the Just Me Services. Just Me does this on the basis that it is necessary in Just Me’s legitimate interest to ensure that the Just Me Services are being provided in the best possible way.</li>
			    			<li>To send you periodic email or notifications about the Just Me Services and any updates or changes to them. Just Me does this on the bases that:</li>
			    			<ul className={`${s.list_disc}`}>
			    				<li>it is necessary in Just Me’s legitimate interest to keep you up to date on the Just Me Services; and</li>
			    				<li>some communications with you are necessary for the performance of Just Me’s contract with you under which it is obliged to provide you with the Just Me Services.</li>
			    			</ul>
			    			<li>To process financial transactions relating to the Partner’s provision of services to you. Just Me does this on the basis that it is necessary for the performance of Just Me’s contract with you, i.e. to introduce you to Partners and process payments.</li>
			    			<li>To verify users’ contact details, and to confirm the identity of and maintain security and safety standards in relation to, users of the Just Me Services. Just Me does this on the bases that:</li>
			    			<ul className={`${s.list_disc}`}>
			    				<li>it is necessary for the performance of Just Me’s contract with you, as part of that contract includes an obligation on Just Me to vet users, including you, for safety purposes; and</li>
			    				<li>it is necessary in Just Me’s legitimate interest to ensure that all users of the Just Me Services are safe and secure at all times.</li>
			    			</ul>			  			
			    		</ul>
			    		<p className={s.text}>Just Me may review your personal data, the Log Files if it has reason to believe that the Just Me Services have been used unlawfully or illegally, or any services booked by a user through the Just Me Services have resulted in an unlawful or illegal act. Just Me does this on the bases that:</p>
		   				<ul className={`${s.list_disc}`}>
		   					<li>it is necessary in Just Me’s legitimate interest to ensure that the Just Me Services are being used appropriately and lawfully; and</li>
		   					<li>where relevant, it is necessary to protect your or any third party’s vital interests – this will only be the basis where Just Me has reason to believe that you or any third party have or may come to harm.</li>

		   				</ul>
		   				<p className={s.text}>In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you. </p>
		   				<p className={s.text}>As part of the registration process Just Me reserves the right to use any personal data provided to obtain further personal data from third parties in the interest of safety and security of Just Me Services and its customers. It does this on the basis that it is necessary in Just Me’s legitimate interest to ensure that all users of the Just Me Services are safe and secure at all times.</p>
		    	
			    		<h3 className={`${s.title__big} ${s.title__spacing}`}>4. Does Just Me disclose any information to third parties?</h3>
			    		<p className={s.text}>Just Me will send your phone number and email address to third parties for the purposes of verifying the legitimacy and validity of the phone number and email address. Just Me does this on the bases that it is in its legitimate interest to prevent fake and/or fraudulent profiles being created.</p>
			    		<p className={s.text}>Just Me does not sell, trade, or otherwise transfer your personal identifiable information to outside parties other than as set out in this privacy policy.</p>
			    		<p className={s.text}>Just Me will not transfer your personal data outside the EEA save for Turkey and the United States of America. Just Me will ensure that adequate safeguards are in place in relation to that data and have entered into arrangement with third parties to ensure that is the case.</p>
			    		<p className={s.text}>Just Me will disclose your personal data to third parties:</p>
			    		<ul className={s.list_disc}>
			    			<li>if Just Me sells or buys any business or assets, in which case Just Me will disclose your personal data to the prospective seller or buyer of such business or asset;</li>
			    			<li>if Just Me or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets; or</li>
			    			<li>if Just Me is under a duty to disclose or share your personal data in order to comply with any legal obligation, or to protect the rights, property, or safety of Just Me, you, other users or any third party. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</li>
			    		</ul>

			    		<h3 className={`${s.title__big} ${s.title__spacing}`}>5. How does Just Me handle and protect your personal data?</h3>
		    	
			    		<p className={s.text}>All information collected, processed and used by Just Me is kept secure. Just Me implements a series of security measures to maintain the safety of your information, including password protection and encryption. </p>
			    		<h3 className={`${s.title__big} ${s.title__spacing}`}>6. How long does Just Me hold your personal data?</h3>
			    		<p className={s.text}>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
			    		<p className={s.text}>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the general time between customers returning to Just Me to make bookings, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>
			    		<h3 className={`${s.title__big} ${s.title__spacing}`}>7. Your Rights</h3>
			    		<p className={s.text}>Where Just Me relies on consent as the condition for processing your personal data, you have the right to withdraw that consent at any time.</p>
			    		<p className={s.text}>You have various statutory rights in relation to your personal data. In particular, you have the right to:</p>
			    		<ul className={s.list_disc}>
			    			<li>object to direct marketing communications at any time;</li>
			    			<li>request access to your personal data;</li>
			    			<li>rectification or erasure of your personal data, in certain circumstances;</li>
			    			<li>object to or restrict the processing of your personal data in certain circumstances;</li>
			    			<li>have your personal data stored in a manner in which it is portable from the Just Me environment to another environment; and</li>
			    			<li>file a complaint with the Information Commissioner’s Office.</li>
			    		</ul>
			    		<h3 className={`${s.title__big} ${s.title__spacing}`}>8. Changes to Privacy Policy</h3>
			    		<p className={s.text}>If Just Me decides to change this privacy policy, any changes will be posted on this page.</p>
			    		<p className={s.text}>This privacy policy was last modified on 06th November 2019.</p>
			    		<h3 className={`${s.title__big} ${s.title__spacing}`}>9. Contact</h3>
			    		<p className={s.text}>If you wish to contact Just Me in relation to this privacy policy, including in relation to any of the rights set out in section 7 above, please contact us at <a href="mailto:info@justme.net">info@justme.net</a> .</p>
		    	</div>
		    </Container>
	    </div>

	    <div className={`${s.sectionlanding}`}>
	    	<Container>
	    		<div className={s.container_doc}>
	    				<h1 className={s.title__subtitle}>Cookie Policy</h1>
	    				<p className={s.text}>Just Me, along with its third-party providers, set and use cookies and similar technologies to store and manage user preferences, deliver targeted advertising, enable content, and gather analytic and usage data. The use of cookies and other tracking technologies is standard across websites and apps through which information is collected about your online activities across applications, websites, or other services. More information about how Just Me uses cookies and similar technologies and how you can control and manage them is below.</p>

			    		<h3 className={s.title__big}>1. What is a Cookie?</h3>
			    		<p className={s.text}>
			    			A cookie is a small text file that is placed on a computer or other device and is used to identify the user or device and to collect information. Cookies are typically assigned to one of four categories, depending on their function and intended purpose: absolutely necessary cookies, performance cookies, functional cookies, and cookies for marketing purposes.
						</p>

			    		<h3 className={s.title__big}>2. Types of Cookies and Why We Use Them</h3>
			    		<ul className={`${s.list_roman}`}>
			    			<li>
								<strong>Absolutely necessary cookies:</strong> These cookies are essential to enable you to move around a website and use its features. Without these cookies particular services cannot be properly provided.
			    			</li>
			    			<li>
								<strong>Performance cookies:</strong> These cookies collect information about how you use the Just Me Services. Information collected includes, for example, the Internet browsers and operating systems used, the domain name of the website previously visited, the number of visits, average duration of visit, and pages viewed. These cookies do not collect information that personally identifies you and only collect aggregated and anonymous information. Performance cookies are used to improve the user-friendliness of a website and enhance your experience.
			    			</li>
			    			<li>
								<strong>Functionality cookies:</strong> These cookies allow the Just Me Services to remember choices you make and provide enhanced, more personal features. These cookies can also be used to remember changes you have made to text size, fonts, and other customisable parts of web pages. They may also be used to provide services you have asked for. The information these cookies collect may be anonymised, and they cannot track your browsing activity on other websites.
			    			</li>
			    			<li>
								<strong>Targeting and advertising cookies:</strong> These cookies track browsing habits and are used to deliver targeted (interest-based) advertising. They are also used to limit the number of times you see an ad and to measure the effectiveness of advertising campaigns. They are usually placed by advertising networks with the website operator’s permission. They remember that you have visited a website and this information is shared with other organisations, such as advertisers.
							</li>
			    		</ul>
			    		<p className={s.text}>You can find more information about the individual cookies we use and the purposes for which we use them in the list below:</p>
			    		<ul className={`${s.list_roman}`}>
			    			<li>
								<h4>Functionality:</h4>
								<p className={s.text}><strong>Purpose:</strong> Just Me uses many cookies to make the Just Me Services work. These include cookies that customise your experience, recognise you when you return to the Just Me Services, allow you to log in, move information from page to page as you navigate the Just Me Services and generally improve your user experience.</p>
			    			</li>
			    			<li>
								<h4>Analytics:</h4>
								<p className={s.text}><strong>Purpose:</strong> Cookies collect information and report website usage statistics without personally identifying individual visitors to Google. In addition to reporting website usage statistics, Google Analytics can also be used, together with some of the advertising cookies described below, to help show more relevant ads on Google properties (like Google Search) and across the web and to measure interactions with the ads we show.</p>
			    			</li>

			    			<li>
								<h4>Advertising:</h4>
								<p className={s.text}><strong>Purpose:</strong> Just Me uses cookies to make advertising more engaging to users and more valuable to publishers and advertisers. Some common applications of cookies are to select advertising based on what’s relevant to a user; to improve reporting on campaign performance; and to avoid showing ads the user has already seen.</p>
								<p className={s.text}>Google uses cookies like NID and SID to help customize ads on Google properties, like Google Search. For example, Just Me uses such cookies to remember your most recent searches, your previous interactions with an advertiser’s ads or search results, and your visits to an advertiser’s website. This helps us to show you customized ads on Google.</p>
								<p className={s.text}>Sometimes advertising cookies may be set on the domain of the site you’re visiting. In the case of advertising Just Me serves across the web, cookies may be set on the domain of the site you’re visiting. Unlike cookies that are set on Google’s own domains, these cookies can’t be read by Google when you’re on a site other than the one on which they were set. They serve purposes such as measuring interactions with the ads on that domain and preventing the same ads from being shown to you too many times.</p>
								<p className={s.text}>Google also uses conversion cookies, whose main purpose is to help advertisers determine how many times people who click on their ads end up purchasing their products. These cookies allow Google and the advertiser to determine that you clicked the ad and later visited the advertiser’s site. Conversion cookies are not used by Google for personalized ad targeting and persist for a limited time only. A cookie named ‘Conversion’ is dedicated to this purpose. It’s generally set in the googleadservices.com domain or the google.com domain (you can find a list of domains we use for advertising cookies at the foot of this page). Some of Just Me’s other cookies may be used to measure conversion events as well. For example, DoubleClick and Google Analytics cookies may also be used for this purpose.</p>

			    			</li>

			    		</ul>
			    		<p className={s.text}>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>




			    		<h3 className={`${s.title__big} ${s.title__spacing}`}>3. Managing Cookies</h3>
			    		<p className={s.text}>You can manage website cookies in your browser settings, and you always have the choice to change these settings by accepting, rejecting, or deleting cookies. If you choose to change your settings, you may find that certain functions and features will not work as intended on the Just Me Services. All browser settings are slightly different, so to manage cookies, you should refer to the relevant settings within your browser.</p>
			    		<p className={s.text}>We understand that you may want to know more about cookies. Here are some useful resources that provide detailed information about types of cookies, how they are used, and how you can manage your cookie preferences: www.aboutcookies.org or www.allaboutcookies.org.</p>


		    	</div>
		    </Container>
	    </div>



	    <div className={s.footer}>
	    	<Container>
		    	<div className={s.footer__flex}>
		    		<div className={s.footer__col}>
		    			<ul className={s.footer__social}>
		    				<li>
		    					<a target="_blank" rel="noopener" href="https://www.facebook.com/justmeforbeauty">
									<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
										<title>Facebook</title>
										<path d="M19.32924,46V25.92955h-6.75413v-7.82187h6.75413v-5.76839C19.32924,5.64511,23.41782,2,29.38949,2 c2.86049,0,5.31894,0.21297,6.03539,0.30816v6.99581l-4.14167,0.00188c-3.24773,0-3.87657,1.54327-3.87657,3.80792v4.99391h7.74553 l-1.0085,7.82187h-6.73704V46H19.32924z"/>
									</svg>
		    					</a>
		    				</li>
		    				{
		    					/*
			    				<li>
			    					<a target="_blank" rel="noopener" href="">
										<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
											<title>Twitter</title>
											<path d="M48,9.1c-1.8,0.8-3.7,1.3-5.7,1.6c2-1.2,3.6-3.1,4.3-5.4c-1.9,1.1-4,1.9-6.3,2.4c-1.8-1.9-4.4-3.1-7.2-3.1 c-5.4,0-9.8,4.4-9.8,9.8c0,0.8,0.1,1.5,0.3,2.2C15.5,16.2,8.2,12.3,3.3,6.3C2.5,7.8,2,9.4,2,11.2c0,3.4,1.7,6.4,4.4,8.2 c-1.6-0.1-3.1-0.5-4.5-1.2c0,0,0,0.1,0,0.1c0,4.8,3.4,8.8,7.9,9.7c-0.8,0.2-1.7,0.3-2.6,0.3c-0.6,0-1.3-0.1-1.9-0.2 c1.3,3.9,4.9,6.8,9.2,6.8c-3.4,2.6-7.6,4.2-12.2,4.2c-0.8,0-1.6,0-2.3-0.1c4.4,2.8,9.5,4.4,15.1,4.4c18.1,0,28-15,28-28 c0-0.4,0-0.9,0-1.3C45,12.8,46.7,11.1,48,9.1z"></path>									
										</svg>				
			    					</a>
			    				</li>

		    					*/
		    				}
		    				<li>
		    					<a target="_blank" rel="noopener" href="https://www.instagram.com/justme4justyou/">
									<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
										<title>Instagram</title>
										<path d="M24,4.324c6.408,0,7.167,0.024,9.698,0.14c2.731,0.125,5.266,0.672,7.216,2.622 c1.95,1.95,2.497,4.485,2.622,7.216c0.115,2.531,0.14,3.29,0.14,9.698s-0.024,7.167-0.14,9.698 c-0.125,2.731-0.672,5.266-2.622,7.216c-1.95,1.95-4.485,2.497-7.216,2.622c-2.53,0.115-3.289,0.14-9.698,0.14 s-7.168-0.024-9.698-0.14c-2.731-0.125-5.266-0.672-7.216-2.622c-1.95-1.95-2.497-4.485-2.622-7.216 c-0.115-2.531-0.14-3.29-0.14-9.698s0.024-7.167,0.14-9.698c0.125-2.731,0.672-5.266,2.622-7.216c1.95-1.95,4.485-2.497,7.216-2.622 C16.833,4.349,17.592,4.324,24,4.324 M24,0c-6.518,0-7.335,0.028-9.895,0.144c-3.9,0.178-7.326,1.133-10.077,3.884 c-2.75,2.75-3.706,6.175-3.884,10.077C0.028,16.665,0,17.482,0,24c0,6.518,0.028,7.335,0.144,9.895 c0.178,3.9,1.133,7.326,3.884,10.077c2.75,2.75,6.175,3.706,10.077,3.884C16.665,47.972,17.482,48,24,48s7.335-0.028,9.895-0.144 c3.899-0.178,7.326-1.133,10.077-3.884c2.75-2.75,3.706-6.175,3.884-10.077C47.972,31.335,48,30.518,48,24s-0.028-7.335-0.144-9.895 c-0.178-3.9-1.133-7.326-3.884-10.077c-2.75-2.75-6.175-3.706-10.077-3.884C31.335,0.028,30.518,0,24,0L24,0z"></path> <path d="M24,11.676c-6.807,0-12.324,5.518-12.324,12.324S17.193,36.324,24,36.324 S36.324,30.807,36.324,24S30.807,11.676,24,11.676z M24,32c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S28.418,32,24,32z"></path> <circle cx="36.811" cy="11.189" r="2.88"></circle>
									</svg>
		    					</a>
		    				</li>
		    				{
			    				/*
			    				<li>

			    					<a target="_blank" rel="noopener" href="">
										<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
											<title>LinkedIn</title>
											<path d="M46,0H2C0.9,0,0,0.9,0,2v44c0,1.1,0.9,2,2,2h44c1.1,0,2-0.9,2-2V2C48,0.9,47.1,0,46,0z M14.2,40.9H7.1V18 h7.1V40.9z M10.7,14.9c-2.3,0-4.1-1.8-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1c2.3,0,4.1,1.8,4.1,4.1C14.8,13,13,14.9,10.7,14.9z M40.9,40.9 h-7.1V29.8c0-2.7,0-6.1-3.7-6.1c-3.7,0-4.3,2.9-4.3,5.9v11.3h-7.1V18h6.8v3.1h0.1c0.9-1.8,3.3-3.7,6.7-3.7c7.2,0,8.5,4.7,8.5,10.9 V40.9z"/>
										</svg>
			    					</a>
			    				</li>
			    				*/


		    				}

		    			</ul>
		    		</div>


		    		<div className={s.footer__col}>
		    		{
		    			/*
		    			<div className={s.footer__appcontainer}>
		    			{ data.applebadge &&
						    <Img
						    	className={s.footer__appbadge}
						      	fluid={data.applebadge.childImageSharp.fluid}
						    />
		    			}
		    			</div>
		    			<div className={s.footer__appcontainer}>
		    			{ data.googlebadge &&
						    <Img
						    	className={s.footer__appbadge}
						      	fluid={data.googlebadge.childImageSharp.fluid}
						    />
		    			}
		    			</div>

		    			*/
		    		}


		    		</div>
		    	</div>
		    	<div className={`${s.footer__flex} ${s.footer__subfooter}`}>
	    			<div className={s.footer__subfooter__credits}>
	    				All rights reserved &copy;  { new Date().getFullYear() }
	    			</div>
	    			<div className={s.footer__subfooter__copyright}>
	    				Site by <a href="https://vital.agency/" rel="noopener" target="_blank" className={s.footer__subfooter__copyright__link}>Vital Agency</a>
	    			</div>
		    	</div>
	    	</Container>
	    </div>

	  </LandingLayout>
	)
};

