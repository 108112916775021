import * as React from 'react';

import { Link } from 'components/link/Link';
import { Button } from 'components/buttons/Button';
import { Container } from 'components/container/Container';


import Logo from 'assets/svg/logo.svg';
import LogoWhite from 'assets/svg/logo-white.svg';

import map from 'lodash/map';

import s from './LandingHeader.scss';

interface ILandingHeaderProps {
  content: any;
  isScrolled?: boolean;
}


const scrollTo = (idelem) => {
    const elem = document.getElementById(idelem)
    if(elem) {
      const thisY = elem.offsetTop -90
      window.scrollTo({
          top: thisY,
          behavior: 'smooth'
      })
    }
}


const RenderLink = ({ item }) => {
  if(item.button){
    return(
      <li className={s.headerapp__menu__li}>
        {
          item.anchor && 
          <Button onClick={ () => { scrollTo(item.to) } }  className={s.headerapp__menu__button}>
            {item.name}
          </Button>
        }
        {
          !item.anchor && 
          <Link
            className={s.headerapp__menu__button}
            to={item.to}
          >
            {item.name}
          </Link>
        }

      </li>
    )
  }

  return(
    <li className={s.headerapp__menu__li}>
        {
          item.anchor && 
          <a onClick={ () => { scrollTo(item.to) } }  className={s.headerapp__menu__a}>
            {item.name}
          </a>
        }
        {
          !item.anchor && 
          <Link
            className={s.headerapp__menu__a}
            to={item.to}
          >
            {item.name}
          </Link>
        }

    </li>
  )
}

export const LandingHeader = ({ content }: ILandingHeaderProps) => {
  let headerClasses = s.headerapp
  
  // if(isScrolled){
  //   headerClasses = `${s.headerapp} ${s.headerapp__white}`
  // }




  return (
    <header className={ `${s.headerapp} ${s.headerapp__white}` }>
      <Container>
        <div className={s.headerapp__inner}>
          <Link
            to="/"
            className={s.headerapp__logo}
            aria-label="JustMe - Just for you"
          >
           <Logo className={s.headerapp__logoSvg} />
           <LogoWhite className={s.headerapp__logoSvg_white} />
          </Link>
          {
            /*

          <div className={s.headerapp__nav}>
            <ul className={s.headerapp__menu}>
              {
                map(content, (item,key) => <RenderLink key={key} item={item} /> )
              }
            </ul>

          </div>
            */
          }
        </div>
      </Container>
    </header>
  )
};
