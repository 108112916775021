import * as React from 'react';

import AppLayout from './app-layout/AppLayout'
import LandingLayout from './landing-layout/LandingLayout'


export { AppLayout, LandingLayout }

// export default ({ children, pageContext }) => {
//   if (pageContext.layout === 'landing') {
//     return <LandingLayout>{children}</LandingLayout>
//   }
//   return <AppLayout>{children}</AppLayout>
// }