import * as React from 'react';
import Helmet from 'react-helmet';

import { LandingHeader } from 'components/landing-header/LandingHeader'

import { helmet } from 'utils/helmet';

import s from './LandingLayout.scss';

export default class LandingLayout extends React.Component {
  // constructor(props){
    // super(props);
    // this.state = { y: 0 };
    // this.handleScroll = this.handleScroll.bind(this)
  // }

  // componentDidMount() {
  //   window.addEventListener('scroll', this.handleScroll);
  // }
  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }

  // handleScroll(ev) {
  //   this.setState({
  //     y: window.scrollY
  //   });
  // }

  render() {
    const { children, menuItems } = this.props
    return(
      <div className={s.main}>
        <Helmet {...helmet}>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_TAGMANAGER_ID}`}></script>
          <script>
              {
              `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
              gtag('config', '${process.env.GOOGLE_TAGMANAGER_ID}');`
              }
          </script>
        </Helmet>

        <LandingHeader content={ menuItems } />
        <div className={s.main__wrap}>
          { children }
        </div>


      </div>
    )

  }
};
