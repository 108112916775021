import * as React from 'react';

interface ILinkProps {
  children?: React.ReactNode;
  [key: string]: any;
}

export const Link = ({ children, ...props }: ILinkProps) => {
  let Component;


  Component = require('gatsby').Link;


  return (
    <Component {...props}>
      {children}
    </Component>
  );
};